import React from 'react';
import PropTypes from 'prop-types';

const Settings = ({
  color,
  className,
}) => (
  <svg viewBox="0 0 200 200" className={className}>
    <path
      fill={color}
      d="M100.932 123.348c-12.1 0-21.9-9.8-21.9-21.9s9.8-22
      21.9-22 21.9 9.9 21.9 22-9.8 21.9-21.9 21.9zm0-40c-10 0-18.1 8.1-18.1 18.1s8.1
      18 18.1 18 18-8.1 18-18-8.1-18.1-18-18.1z"
    />
    <path
      fill={color}
      d="M90.232 147.248h-.4c-7-1.6-13.6-4.8-19.3-9.3-.4-.3-.7-.8-.7-1.3s.1-1
      .4-1.5l2.7-3.4c-3.7-3.2-6.8-7.1-9.1-11.4l-4 1.9c-1 .5-2.2.1-2.7-.9-3.1-6.5-4.7-13.5-4.7-20.8 0-.5.2-1
      .6-1.4.4-.4.9-.6 1.4-.6h4.4c.2-4.9 1.3-9.7 3.3-14.3l-4-1.9c-.5-.2-.8-.6-1-1.1-.2-.5-.1-1
      .1-1.5 3-6.5 7.6-12.3 13.2-16.8.8-.7 2.1-.6 2.8.3l2.8 3.4c4-2.9 8.4-5 13.2-6.4l-1-4.3c-.1-.5
      0-1.1.3-1.5.3-.4.7-.8 1.2-.9 6.9-1.6 14.4-1.6 21.3-.1 1.1.2 1.7 1.3 1.5 2.4l-1 4.3c4.7 1.3 9.2 3.5
      13.2 6.3l2.7-3.4c.3-.4.8-.7 1.3-.7.5-.1 1 .1 1.5.4 5.6 4.5 10.2 10.3 13.3 16.7.2.5.3 1 .1 1.5-.2.5-.5.9-1 1.1l-4
      1.9c2 4.6 3.1 9.4 3.3 14.3l4.3-.1c.5 0 1 .2 1.4.6.4.4.6.9.6 1.4 0 7.5-1.6 14.5-4.7 21-.2.5-.6.8-1.1 1s-1
      .1-1.5-.1l-4-1.9c-2.3 4.3-5.4 8.2-9.1 11.5l2.7 3.4c.7.9.5 2.1-.3 2.8-5.6 4.5-12.3
      7.7-19.2 9.3-1.1.3-2.1-.4-2.4-1.5l-1-4.3c-4.9.9-9.7.9-14.6 0l-1 4.3c-.1.5-.4
      1-.9 1.2-.2.3-.5.4-.9.4zm-15.6-11.2c4.3 3.1 9.1 5.4 14.1 6.8l.9-4.2c.1-.5.4-1 .9-1.2.4-.3 1-.4
      1.5-.3 5.6 1.3 11.1 1.3 16.8 0 .5-.1 1.1 0 1.5.3s.8.7.9 1.2l1 4.2c5.1-1.4 9.9-3.7
      14.1-6.8l-2.7-3.4c-.7-.9-.5-2.1.3-2.8 4.4-3.5 8-8 10.4-13.1.5-1 1.7-1.4 2.7-.9l3.9 1.9c2.1-4.9
      3.3-10.1 3.5-15.4l-4.3.1c-.5 0-1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4
      0-5.7-1.3-11.2-3.8-16.4-.5-1-.1-2.2.9-2.7l3.9-1.9c-2.5-4.6-5.9-8.8-9.8-12.2l-2.7
      3.4c-.7.9-1.9 1-2.8.3-4.5-3.5-9.6-6-15.1-7.2-1.1-.2-1.7-1.3-1.5-2.4l.9-4.2c-5.1-1-10.6-1-15.7 0l1
      4.2c.1.5 0 1.1-.3 1.5-.3.4-.7.8-1.2.9-5.5 1.2-10.7 3.7-15.1 7.3-.9.7-2.1.6-2.8-.3l-2.7-3.3c-3.9 3.5-7.3
      7.7-9.8 12.3l3.9 1.9c1 .5 1.4 1.7.9 2.6-2.5 5.2-3.7 10.6-3.7 16.3 0 1.1-.9 2.1-2 2.1l-4.2-.2c.2 5.3 1.4
      10.4 3.5 15.3l3.9-1.9c.5-.2 1-.3 1.5-.1s.9.5 1.1 1c2.5 5.1 6.1 9.6 10.5 13.1.4.3.7.8.7 1.3s-.1 1-.4 1.5l-2.6 3.4z"
    />
  </svg>
);

Settings.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Settings.defaultProps = {
  className: '',
  color: 'white',
};

export default Settings;
